<template>
    <div class="menus" style="background: none;">
        <div class="disBoard">
            <div class="poset">
                <span style="font-weight: bold;font-size: 30px;">{{NumberInstitutions}}</span>
                <p>机构数量</p>
            </div>
        </div>
        <div style="text-align: left;height: 50px;line-height: 50px;font-weight: bold;">学校阶段人数</div>
            <el-row :gutter="20">
                <el-col :span="4" v-for="(item,index) in gradation" :key="index">
                    <div class="box" >
                        <div class="imgs">
                            <img :src="item.img" alt="Your Image">
                        </div>
                        <div class="text-container">
                            <p>{{item.num}} <span>人</span> </p>
                            <p>{{item.name}}</p>
                        </div>
                    </div>
                </el-col>
              </el-row>
      
        <div style="margin-top: 25px;">
            <el-row :gutter="20">
                <el-col :span="15">
                    <div class="grid-content bg-purple">
                        <div ref="main" class="bingIner" id="myChart"></div>
                    </div>
                </el-col>
                <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                        <treeselect 
                          style="width: 380px;position:absolute;right: 5px;top:5px;z-index: 9999;"
                          v-model="parentid" 
                          :show-count="true"
                          :options="schoolOptions" 
                          :normalizer="normalizer" 
                          :disableBranchNodes="disableBranchNodes"
                          :beforeClearAll="beforeClearAll"
                          @select="departTreeSelected"
                          placeholder="请选择" />
                        <!-- <el-select v-model="value" 
                            placeholder="请选择" 
                            clearable
                            @change="handleChange"
                            style="width:380px;height:30px; position: absolute;right: 5px;top:45px;z-index: 99;">
                            <el-option
                              v-for="item in optionset"
                              :key="item.name"
                              :label="item.name"
                              :value="item.name"
                             >
                            </el-option>
                        </el-select> -->
                        <div ref="mase" class="bingIner"></div>
                        
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import Treeselect from '@riophae/vue-treeselect'
    // import the styles
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import { schoolList}  from '@/api/user/shcool'
    import { queryStage,classification,queryPic,queryInstitutionc,queryCertificate}  from '@/api/statistics/index'
export default {
    components: { Treeselect },
    data() {
        return {
            parentid:null,
            // 学校树选项
            schoolOptions: [],
            value:'',
            disableBranchNodes:true,
            imgs:[
                require('../../assets/images/1x.png'),
                require('../../assets/images/2x.png'),
                require('../../assets/images/3x.png'),
                require('../../assets/images/4x.png'),
                require('../../assets/images/5x.png'),
                require('../../assets/images/6x.png'),
            ],
            optionset: [
            ],
            gradation:[],
            count:[],
            title:[],
            NumberInstitutions:''
        }
    },
    created() {
        this.sefset()
        this.Stage()
        this.zhuzhaugntu(1)
        this.Pie('','','')
        this.abcst()
        queryInstitutionc().then(res=>{
            this.NumberInstitutions = res.data
        })
    },
    mounted() {
       

    },
    methods: {
        // 查询证书时间
        abcst(){
            queryCertificate().then(res=>{
                let arry = res.data
                // console.log(res)
                const setList = arry.map(item =>{
                   return {
                      value:item.startTime+'/'+item.endTime,
                      label:item.startTime+'/'+item.endTime,
                      name:item.certificateName
                   }
                })
                this.optionset = setList
                // console.log(this.optionset)
            })
        },

        // 查询阶段人数
        Stage(){
            queryStage().then(res => {
                let populations  = res.data.count
                let arry = res.data.stage
                let schools  = []
                for(var item of arry){
                    if(item === 1){
                        schools .push('幼儿园')
                    }else if(item === 2){
                        schools .push('小学')
                    }else if(item === 3){
                        schools .push('中学')
                    }else if(item === 4){
                        schools .push('高中')
                    }else if(item === 5){
                        schools .push('职高')
                    }else if(item === 6){
                        schools .push('其他')
                    }
                }
                const schoolData = schools.map((school, index) => ({
                    name: school,
                    num: populations[index],
                    img:this.imgs[index]
                }));
                this.gradation = schoolData
                
                
            })
        },


        // 柱状图数据
        zhuzhaugntu(type){
            let obj = {
                state:type
            }
            classification(obj).then(res => {
                let list = res.data
                let num = []
                let name = []
                for(let item of list){
                    name.push(item.title)
                    num.push(item.count)
                }
               
                // this.count = num
                // this.title = name
                // console.log(this.count)
                // console.log(this.title)
                var myChart = this.$echarts.init(this.$refs.main);
                myChart.setOption({
                    color: [
                      '#027AFF',
                    ],
                    source: [
                    ],
                    title: {
                       text: '题库数'
                    },
                    tooltip: {},
                    xAxis: {
                        data: name,
                        // data:this.title
                    },
                    yAxis: {
                        // data: [0, 5, 10, 15, 20, 25, 30, 35, 40],
                        // data:this.count
                    },
                    grid:{ // 让图表占满容器
                        top:"50px",
                        left:"50px",
                        right:"50px",
                        bottom:"20px"
                    },
                    series: [
                        {
                            name: '', //
                            type: 'bar',  // 设置图表类型（柱状图， 饼图.......）
                            data: num,
                            barWidth: 20 // 设置每个柱子的宽度为40
                        },
                    ],
                    // type的slider和inside可以同时保留，既可以保留滚动条，也可以在内部拖动
                    dataZoom : [
                        //在内部可以横向拖动
                            {
                                type: 'inside',// 内置于坐标系中
                                start: 0,
                                end: 15,
                                xAxisIndex: [0]
                            },
                    ],
                
                });
            })
        },
       
        // 饼图数据
        Pie(schoolId,name){
            let obj = {
                id:schoolId,
                certificateName:name,
            }
            queryPic(obj).then(res=>{
                let data = res.data
                var piChart = this.$echarts.init(this.$refs.mase);
                piChart.setOption({
                        title: {
                        text: '总人数'
                        },
                        legend: {
                            left: 200,
                            bottom: 50,
                            width: 500,   //尺寸需要自己调节
                            height: 20,
                            // formatter: ["{a|{name}}"].join("\n"),
                            formatter: params => {
                                let arr = [{name:'已通过',value:data.passCount},{name:'未通过',value:data.count-data.passCount}] //图表数据
                                let item = arr.filter(i => {
                                return i.name == params
                                })[0]
                                if (!item) return
                            
                                return [`{title|${params}}`+ ` ${item.value}`]
                            },
                            textStyle: {
                            rich: {
                                a: {
                                width: 95,
                                fontSize: 14,
                                lineHeight: 12,
                                },
                            },
                            },

                        },
                        color: ['#006AFE', '#CFE4FF'],//五个数据，五个颜色
                        series: [
                            {
                                type: 'pie',      //type为pie，表示图表为饼图
                                radius: ['40%', '50%'],
                                data: [
                                    {
                                        value: data.passCount,
                                        name: '已通过'
                                    },
                                    {
                                        value: data.count-data.passCount,
                                        name: '未通过'
                                    },
                                ],
                                label: {
                                    normal: {
                                        position: 'center',
                                        color:'#4c4a4a',
                                        formatter: '{total|' + data.count +'}'+ '\n\r' + '{active|总人数}',
                                        rich: {
                                            total:{
                                                fontSize: 35,
                                                fontFamily : "微软雅黑",
                                                color:'#006AFE'
                                            },
                                            active: {
                                                fontFamily : "微软雅黑",
                                                fontSize: 16,
                                                color:'#6c7a89',
                                                lineHeight:30,
                                            },
                                        }
                                    },
                                    
                                },
                            },
                        ]
                });
            })
        },
        


        // 查询机构/学校
        sefset(){
            schoolList().then(res => {
                    const school = { id: 0, title: '主类目', children: [] };
                    school.children = this.handleTree(res.data,'id','parentid');
                    this.schoolOptions = this.handleTree(res.data,'id','parentid');
            });
        },
        normalizer(node) {
                if (node.children && !node.children.length) {
                    delete node.children;
                }
                return {
                    id: node.id,
                    label: node.title,
                    children: node.children
                };
        },
        departTreeSelected(node,instanceId){
            
               let ser = ''
               ser = this.value
               console.log(this.value)
            if(this.ser !== ''){
                ser = this.value
            }else{
                ser = ''
            }
            this.Pie(node.id,ser)
        },
        beforeClearAll(node){
            this.parentid = null
            this.Pie('','')
        },
        handleChange(){
            let time = this.value
            console.log(time)
            let id = this.parentid 
            if(id === null) id = ''
            this.Pie(id,time)
        },
    },
}
</script>

<style scoped>
   .disBoard{
      height: 235px;
      background-image:url('../../assets/images/112x.png');
      background-size: 100% 100%;
      position: relative;
   }
   .poset{
        width: 180px;
        height: 100px;
        color: #1D1D1D;
        /* background: yellowgreen; */
        text-align: left;
        position: absolute;
        left:400px;
        bottom: 0;

   }
   .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  ::v-deep .el-input__inner{
     height: 36px !important;
  }
  

   /* .container {
        display: flex;
        justify-content: space-between;
   } */

    .box {
        height:130px;
        background-color: #FFFFFF; /* 根据需要调整盒子的背景颜色 */
        border-radius: 10px;
    }
    .box .imgs {
        float: left;
        width: 50%; /* 根据需要调整图片宽度 */
        height: 130px;
        max-width: 100%; /* 限制图片的最大宽度 */
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .imgs img{
        width: 80px;
        height: 80px;
    }
    .box .text-container {
        float: right;
        width: 50%; /* 根据需要调整文字容器宽度 */
        box-sizing: border-box; /* 让文字容器包括内边距和边框在内 */
        padding-top: 10px; /* 根据需要调整内边距 */
        text-align: left;
        font-size: 20px;
    }

    .box h2 {
       margin: 0 0 10px; /* 根据需要调整标题行间距 */
    }

    .bingIner {
        width: 100%;
        height: 500px;
    }
  .bg-purple {
    background: #FFFFFF;
    padding:10px;
  }
  .bg-purple-light {
    background: #FFFFFF;
    padding:10px;
    position: relative;
    border-radius: 4px;
  }
  .grid-content {
    border-radius: 4px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #FFFFFF;
  }
</style>