import request from '@/utils/request.js';

export function queryStage() {
    return request({
        url: 'dataCount/selectUserCount',
        method: 'get',
    })
}

export function classification(data) {
    return request({
        url: 'dataCount/selectQuestionCount',
        method: 'get',
        params: data
    })
}

export function queryPic(data) {
    return request({
        url: 'dataCount/selectUserPass',
        method: 'get',
        params: data
    })
}

// 查询机构数量
export function queryInstitutionc() {
    return request({
        url: 'dataCount/selectGroupCount',
        method: 'get',
    })
}


//证书配置
export function queryCertificate() {
    return request({
        url: 'certificateDate/selectCertificateDate',
        method: 'get',
    })
}


